import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

export const BaseArea = styled.div`
	background-color: #F0F0F0;
	padding: 0 16px 80px;
	width: 100%;
	min-height: calc(100vh - 90px);
`;

export const AdminBaseArea = styled.div`
	background-color: #F0F0F0;
	padding: 48px 16px 60px 40px;
	width: 100%;
	min-height: calc(100vh - 90px);
`;


export const MypageBaseArea = styled.div`
	padding: 48px 16px 60px 40px;
	width: 100%;
	min-height: calc(100vh - 90px);
`;

export const ContentsTitleH3 = styled.h3`
    text-align: start;
`;

export const A = styled.a`
	text-decoration: none;
	cursor: pointer;
	color: black;
	&:hover {
		text-decoration: underline;
	}
`;

export const RequiredSpan = styled.span`
	font-size: 0.7em;
	vertical-align: top;
	position: relative;
	top: 0.1em;
	color: var(--color-red--01);
`;

export const LinkMenu = styled.a`
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	display: block;
	width: fit-content;
	padding: 12px;
	color: #fff;
	font-weight: 700;
	line-height: 1;
	&:hover {
		text-decoration: underline;
	}
`

export const GlobalStyle = createGlobalStyle`
	:root {
		--color-site-primary: #2CA7BD;
		--color-site-secondary: #1A8CA1;
		--color-site-tertiary: #00313A;
		--color-txt-primary: #555555;
		--color-txt-secondary: #333333;
		--color-txt-tertiary: #888888;
		--color-txt-placeholder: #878787;
		--color-bg-primary: #EEEEEE;
		--color-bg-secondary: #F0F0F0;
		--color-line-primary: #C2C2C2;
		--color-line-secondary: #878787;
		--color-gray--01: #D9D9D9;
		--color-green--01: #0092C4;
		--color-red--01: #E83939;
		--color-red--02: #F44338;
		--color-blue-01: #1482C0;
		--color-blue-02: #1A76D1;
		--color-orange-01: #E08804;
		--color-green-01: #1AA11F;
		--color-error-primary: #D42C2C;
		--leading-trim: calc((1em - 1lh) / 2);
		--color-site-primary-rgb: 44 167 189;
	}
`;