import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { getCurrentUserInfo } from '@services/Cookies';
import Header from '@common/header/Header';
import SidebarMenu from '@common/sidebar/SidebarMenu';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './i18n';
import { GlobalStyle } from '@styles/Common';
import { FadeLoader } from 'react-spinners';
import { useGetUserDetailData } from '@hooks/useBackendApi';

// 遅延ロードするコンポーネント
const MyPage = lazy(() => import('@pages/MyPage'));
const LogIn = lazy(() => import('@pages/LogIn'));
const ProcessView = lazy(() => import('@pages/process/ProcessView'));
const CaseStudyView = lazy(() => import('@pages/case_study/CaseStudyView'));
const UserManagement = lazy(() => import('@pages/admin/UserManagement'));
const MasterExportModal = lazy(() => import('@specific/admin/MasterExportModal'));
const SharedFileManagement = lazy(() => import('@pages/admin/SharedFileManagement'))
const TabDisplayBySanKeyDiagram = lazy(() => import('@pages/TabDisplayBySankeyDiagram'))
const NotFound = lazy(() => import('@pages/NotFound'));

const App = () => {

    // 認証確認
    const RequireAuth = (props: any) => {
        const currentUser = getCurrentUserInfo();
        if (currentUser) {
            return props.component;
        }
        // 権限がない場合、ログインページへリダイレクト
        document.location = "/login";
    };

    // 非認証確認
    const RequireNoAuth = (props: any) => {
        const currentUser = getCurrentUserInfo();
        if (!currentUser) {
            return props.component;
        }
        // 認証済みの場合マイページへリダイレクト
        document.location = "/";
    };

    // 管理者確認
    const RequireAdmin = (props: any) => {
        const currentUser = getCurrentUserInfo();
        if (!currentUser) {
            document.location = "/login";
        }
        if (currentUser?.is_admin) {
            return props.component;
        }
        document.location = "/";
    };

    return (
        <Router>
            <DndProvider backend={HTML5Backend}>
                <GlobalStyle />
                <Routes>
                    {/* 全ユーザー */}
                    <Route path="/login" element={<RequireNoAuth component={<LogIn />} />} />
                    <Route path="/" element={<RequireAuth component={<MyPage />} />} />
                    <Route path="/process/process_view/:id" element={<RequireAuth component={<ProcessView />} />} />
                    <Route path="/case_study/case_study_view/:id" element={<RequireAuth component={<CaseStudyView />} />} />
                    <Route path="/sankey_diagram_view" element={<RequireAuth component={<TabDisplayBySanKeyDiagram />} />} />
                    <Route path="*" element={<NotFound />} />
                    {/* 管理者 */}
                    <Route path="/admin/user_management" element={<RequireAdmin component={<UserManagement />} />} />
                    <Route path="/admin/master_export_model" element={<RequireAdmin component={<MasterExportModal />} />} />
                    <Route path="/admin/shared_file_management" element={<RequireAdmin component={<SharedFileManagement />} />} />
                </Routes>
            </DndProvider>
        </Router>
    );
};

export default App;
