import { CurrentUser } from '@typeList/api/user';
import Cookies from 'js-cookie';

// クッキー管理
export const setCookies = (key: string, token: string) => {
  Cookies.set(key, token, { expires: 7, secure: true, sameSite: 'strict' }); // 有効期限は7日間
};

export const getCookies = (key: string): string | undefined => {
  return Cookies.get(key);
};

export const removeCookies = (key: string) => {
  Cookies.remove(key);
};

// ユーザー情報
export const setCurrentUserInfo = (token: string) => {
  setCookies('user_info', token);
};

export const getCurrentUserInfo = (): CurrentUser | undefined => {
  const cookieData = getCookies('user_info');
  if (cookieData === undefined) {
    return
  }
  const user = JSON.parse(String(cookieData));
  return user;
};

export const removeCurrentUserInfo = () => {
  removeCookies('user_info');
};

// 多言語情報
export const removei18next = () => {
  removeCookies('i18next');
};

// デフォルトデータパックID
export const setCurrentDefaultDatabasePackId = (id: number | null) => {
  if (id === null || id === undefined) {
    return
  }
  setCookies('default_database_pack_Id', String(id));
};

export const getCurrentDefaultDatabasePackId = (): number | undefined => {
  const cookieData = getCookies('default_database_pack_Id');
  if (cookieData === undefined) {
    return
  }
  return Number(cookieData);
};

export const removeCurrentDefaultDatabasePackId = () => {
  removeCookies('default_database_pack_Id');
};
